<template>


    <div class="list-view">

        <page-title>
            <el-button @click="editRow()" type="primary" icon="el-icon-plus" class="filter-item">
                新增
            </el-button>
        </page-title>

        <pageable url="/sysConfig/all"  downloadUrl="/sysConfig/excel">
           <template v-slot:table >
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="desc" label="描述"> </el-table-column>
                <el-table-column prop="type" label="类型" :formatter="typeFormatter" ></el-table-column>
                <el-table-column prop="value" label="值" show-overflow-tooltip > </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="150">
                    <template v-slot="{ row }">
                        <el-button @click="editRow(row, true)" type="primary" size="mini" plain>编辑</el-button>
                    </template>
                </el-table-column>
           </template>
        </pageable>
        




        <el-dialog :visible.sync="showDialog" width="500px" title="编辑设置" :close-on-click-modal="false">
            <el-form
                :model="formData"
                :rules="rules"
                ref="form"
                label-width="100px"
                label-position="right"
                size="small"
            >
                <el-form-item prop="name" label="名称">
                    <el-input v-model="formData.name" :disabled="edit === true"></el-input>
                </el-form-item>
                <el-form-item prop="desc" label="描述">
                    <el-input v-model="formData.desc"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="类型">
                    <el-select v-model="formData.type" placeholder="请选择" :disabled="edit === true">
                        <el-option
                            v-for="item in valueTypes"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'STRING'">
                    <el-input v-model="formData.value">
                        <template v-slot:append>
                            <div class="upload-btn">
                                <span>上传</span>
                                <input class="inp" type="file" @change="uploadTo" />
                            </div>

                        </template>
                    </el-input>
                </el-form-item>

                <el-form-item prop="value" label="值" v-if="formData.type === 'DATETIME'">
                    <el-date-picker
                        v-model="formData.value"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择日期时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'DATE'">
                    <el-date-picker
                        v-model="formData.value"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'TIME'">
                    <el-time-picker
                        v-model="formData.value"
                        value-format="HH:mm"
                        placeholder="请选择时间"
                        arrow-control
                    ></el-time-picker>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'BOOLEAN'">
                    <el-switch
                        v-model="formData.value"
                        active-text="是"
                        inactive-text="否"
                        active-value="1"
                        inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'NUMBER'">
                    <el-input-number v-model="formData.value"></el-input-number>
                </el-form-item>
                <el-form-item prop="value" label="值" v-if="formData.type === 'FILE'">
                    <file-upload v-model="formData.value" :limit="1"></file-upload>
                </el-form-item>
                <div class="list-wrap" v-if="formData.type === 'LIST'">
                    <el-form-item prop="optionMap" label="可选内容">
                        <el-input
                            type="textarea"
                            v-model="formData.optionMap"
                            placeholder="请输入普通json格式"
                            rows="5"
                            :disabled="edit === true"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="value" label="值">
                        <el-select v-model="formData.value">
                            <el-option
                                v-for="(value, key) in optionMapJson"
                                :key="key"
                                :label="value"
                                :value="key"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer">
                <el-button type="primary" size="mini" @click="save" :loading="saving">保存</el-button>
                <el-button size="mini" @click="showDialog = false">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'SysConfigList',
    data() {
        let validateOptionJson = (rule, value, callback) => {
            if (this.parseOptionMap()) {
                callback();
            } else {
                callback(new Error('请输入正确的json格式'));
            }
        };

        return {
            formData: {
                name: '',
                desc: '',
                value: '',
                type: 'STRING',
                optionMap: ''
            },
            rules: {
                name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
                type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                value: [{ required: true, message: '请输入值', trigger: 'blur' }],
                optionMap: [
                    { required: true, message: '请输入选项内容', trigger: 'blur' },
                    { validator: validateOptionJson, trigger: 'blur' }
                ]
            },
            showDialog: false,
            saving: false,
            edit: false,
            valueTypes: [
                {
                    label: '字符串',
                    value: 'STRING'
                },
                {
                    label: '日期时间',
                    value: 'DATETIME'
                },
                {
                    label: '日期',
                    value: 'DATE'
                },
                {
                    label: '时间',
                    value: 'TIME'
                },
                {
                    label: '开关',
                    value: 'BOOLEAN'
                },
                {
                    label: '数字',
                    value: 'NUMBER'
                },
                {
                    label: '文件',
                    value: 'FILE'
                },
                {
                    label: '列表',
                    value: 'LIST'
                }
            ],
            htmlContents: [''],
            optionMapJson: {}
        };
    },
    computed: {
         
    },
    methods: {
        
        addRow() {
            this.$router.push({
                path: '/sysConfigEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row, edit) {
            this.edit = edit;
            if (!row) {
                row = {
                    name: '',
                    desc: '',
                    value: '',
                    type: null
                };
            }
            this.formData = { ...row };
            this.showDialog = true;
            this.parseOptionMap();
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let data = { ...this.formData };
                    this.saving = true;
                    this.$http
                        .post('/sysConfig/save', data, { body: 'json' })
                        .then(res => {
                            this.saving = false;
                            this.$message.success('成功');
                            this.showDialog = false;
                            this.getData();
                        })
                        .catch(e => {
                            console.log(e);
                            this.saving = false;
                            this.showDialog = false;
                            this.$message.error(e.error);
                        });
                } else {
                    return false;
                }
            });
        },
        typeFormatter(row, cell, cellValue) {
            let item = this.valueTypes.find(i => i.value === cellValue);
            return item ? item.label : '';
        },
        parseOptionMap() {
            let jsonData = {};
            try {
                jsonData = JSON.parse(this.formData.optionMap);
                this.optionMapJson = jsonData;
            } catch (e) {
                //ignore
                return false;
            }

            return true;
        },
        async uploadTo(event){
            if(event && event.currentTarget){
                let target = event.currentTarget;
                if(target.files && target.files.length > 0){
                    let reqData = new FormData();
                    reqData.append("file", target.files[0]);
                    try{
                        let res = await this.$http.post("upload/file", reqData);
                        this.formData.value = res;
                        this.$message.success("上传成功");
                    }catch(e){
                        let err = e.message || e.error;
                        this.$message.error(err);
                    }
                }else{
                    this.$message.error("请选择一个文件");
                }

            }

        }
    }
};
</script>
<style lang="less" scoped>
.list-wrap {
    padding-top: 8px;
    border-top: solid 1px #eee;
}

.upload-btn{
    position:relative;
    width: 80px;
    text-align: center;

    .inp{
        opacity: 0;
        position:absolute;
        top: 0;
        left: 0;
        right:0;
        bottom:0;

    }
}

</style>
